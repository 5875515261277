import React from "react";
import './App.css';
import Header from './components/Header/Header.jsx';
import Main from './components/main/main.jsx';
import Free from './components/Free/Free.jsx';
import Numbers from './components/Numbers/Numbers.jsx';
import Segmentation from './components/Segmentation/Segmentation.jsx';
import Countries from './components/Countries/Countries.jsx';
import Support from "./components/Support/Support";
import Join from "./components/Join/Join";
import Partners from './components/Partners/Partners.jsx';
import Team from './components/Team/Team.jsx';
import Mission from './components/Mission/Mission.jsx';
import Guides from './components/Guides/Guides.jsx';
import TranslateTo from './components/TranslateTo/TranslateTo.jsx';
import Footer from './components/footer/Footer.jsx';
import How_it_works from './components/How_it_works/How_it_works';

const App = (props) => {
  return (
    <main>
      <Header />
      <Main />
      <How_it_works />
      <Free />
      <Numbers />
      <Segmentation />
      <Countries />
      <Support />
      <Join />
      <Partners />
      <Team />
      <Mission />
      <Guides />
      <TranslateTo />
      <Footer />
    </main> 
  );
}

export default App;