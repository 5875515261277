import React from 'react';
import './Segmentation.css';
import diagrama from '../../img/diagrama/diagrama.svg'

const Segmentation = (props) => {
    const state = {
        segmentation_item: [
        {
            num: '52%',
            desc: 'Медичні установи',
        },
        {
            num: '17%',
            desc: 'Соціальні служби',
        },
        {
            num: '11%',
            desc: 'Освіта',
        },
        {
            num: '10%',
            desc: 'Органи місцевої влади',
        },
        {
            num: '10%',
            desc: 'Банки',
        },
      ],
    };

    const renderSegmentation = (item, i) => {
        return (
            <div className="segmentation_item" key={`segmentation_item-${i}`}>
                <p className='segmentation_num'>{item.num}</p>
                <p className='segmentation_desc'>{item.desc}</p>
            </div>
        );
    };

    return (
        <div className="segmentation_wrap">
            <div className='segmentation container'>
                <h2 className='segmentation_title'>Сегментація звернень</h2>
                <div className="segmentation_content">
                    <img className="segmentation_diagrama" src={diagrama} alt="" />
                    <div className="segmentation_items">
                        {state.segmentation_item.map((item, i) => renderSegmentation(item, i))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Segmentation;