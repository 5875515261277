import React from 'react';
import './Numbers.css';

const Numbers = (props) => {
    const state = {
        numbers: [
            {
                num: '430+',
                desc: 'Дзвінків обробляємо на тиждень',
            },
            {
                num: '195',
                desc: 'Волонтерів долучились до команди перекладачів',
            },
            {
                num: '7',
                desc: 'Мов доступних до перекладу',
            },
            {
                num: '6',
                desc: 'Країн Європи в яких ми працюємо',
            },
        ],
    };

    const renderNumbers = (item, i) => {
        return (
            <div className="numbers_item" key={`numbers_item-${i}`}>
                <p className="numbers_item---num">{item.num}</p>
                <p className="numbers_item---desc">{item.desc}</p>
            </div>
        );
    };

    return (
        <div className="numbers_wrap" id='numbers'>
            <div className='container numbers'>
                <h2 className="numbers_title">Ми в цифрах</h2>
                <div className="numbers_content">
                    {state.numbers.map((item, i) => renderNumbers(item, i))}
                </div>
            </div>
        </div>
    );
};

export default Numbers;