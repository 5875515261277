import React from 'react';
import './Support.css';
import Marquee from "react-fast-marquee";
import Popup_sponsors from '../Popup_sponsors/Popup_sponsors'

const Support = (props) => {
    return (
        <div className="support_wrap">
            <Marquee gradient={false} delay={0} childMargin={0} duration={45}>
                <div className='marquee'>
                    <h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3> <h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3> <h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3> <h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3><h3> SUPPORT</h3>
                </div>
            </Marquee>
            <div className='container support' id='support'>
                <h2 className='support_title support_item'>Підтримати<br /> сервіс</h2>
                <div className='support_content support_item'>
                    <p className='support_text'>Сервіс сплачує за хвилини розмов через телефонию та роботу спеціалістів. Зараз ми платимо коштами засновника, але вони швидко закінчуються та ми дуже  потребуємо вашої підтримки.</p>
                    <p className='support_text'>Кожна сума стане в нагоді та допоможе нам помагати нашім.</p>
                    <Popup_sponsors />
                </div>
            </div>
            <Marquee gradient={false} delay={0} childMargin={0} duration={45}>
                <div className='marquee'>
                    <h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3><h3> DONATE</h3>
                </div>
            </Marquee>
        </div>
    );
};

export default Support;