import React from "react";
import "./Footer.css";
import { Link } from "react-scroll";
import footer_logo from "../../img/footer/footer_logo.svg";
import footer_our from "../../img/footer/footer_our.svg";
import footer_our_mob from '../../img/footer/footer_our_mob.svg'

export default class Footer extends React.Component {
  render() {
      return (
          <div className='footer_wrap'>
              <div className='container footer'>
                  <div className='footer_content'>
                    <div className='footer_data'>
                      <img className="footer_logo footer_data--item" src={footer_logo} alt="" />
                      <p className="footer_addres footer_data--item">UL. MODLINSKA, numer 6A, lokal 222, kod poczt. 03-216, poczta WARSZAWA</p>
                      <a className="footer_phone footer_data--item" href="tel:+349 369 40 515">+349 369 40 515</a>
                      <p className="footer_tg footer_data--item">@Pereklad_help</p>
                    </div>
                    <ul className='footer_menu'>
                      <li className='footer_menu--item'>
                        <Link
                        activeClass="active"
                        to='service'
                        spy={true}
                        smooth={true}
                        offset={-94}
                        >
                          Про сервіс
                      </Link>
                      </li>
                      <li className='footer_menu--item'>
                        <Link
                        activeClass="active"
                        to='support'
                        spy={true}
                        smooth={true}
                        offset={-94}
                        >
                        Донорам
                      </Link>
                      </li>
                      <li className='footer_menu--item'>
                        <Link
                        activeClass="active"
                        to='join'
                        spy={true}
                        smooth={true}
                        offset={-94}
                        >
                        Перекладачам
                        </Link>
                      </li>
                      <li className='footer_menu--item'>
                        <Link
                        activeClass="active"
                        to='countries'
                        spy={true}
                        smooth={true}
                        offset={-94}
                        >
                        Доступні країни
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img className='footer_img' src={footer_our} alt="" />
                  <div className="footer_img_block--mob">
                    <img className='footer_img--mob' src={footer_our_mob} alt="" />
                    <p className="footer_tg--mob">@Pereklad_help</p>
                  </div>
                  <p className="footer_lb">Створено за підтримки GidnoFond</p>
              </div>
          </div>
      )
  }
}
