import React from 'react';
import './Guides.css';
import Popup_sponsors from '../Popup_sponsors/Popup_sponsors'

const Guides = (props) => {
    return (
        <div className="guides_wrap" id='guides'>
            <div className="container guides">
                <h2 className="guides_title">Провідники в<br />іншу культуру</h2>
                <div className='guides_content'>
                    <p className="guides_desc">Ми не просто механічно перекладаємо. Ми допомагаємо порозумітись та вирішити важливі питання.</p>
                    <p className="guides_desc">Це сервіс де наші допомагають нашим, тому ми підказуємо та направляємо наших користувачів у спілкуванні. Ми маємо на меті створити комʼюніті яке є надійною опорою на час перебування на чужині.</p>
                    <Popup_sponsors />
                </div>
            </div>
        </div>
    );
};

export default Guides;