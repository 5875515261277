import React from 'react';
import './Countries.css';
import France from '../../img/countries/France.png'
import Germany from '../../img/countries/Germany.png'
import Italy from '../../img/countries/Italy.png'
import Portugal from '../../img/countries/Portugal.png'
import Spain from '../../img/countries/Spain.png'
import UK from '../../img/countries/UK.png'
import call from '../../img/countries/call.svg'
import Switzerland from '../../img/countries/Switzerland.png'
import USA from '../../img/countries/USA.jpg'

const Countries = (props) => {
    const state = {
        countries: [
            {
                flag: France,
                country: 'Франція',
                phone: '+33757690091',
            },
            {
                flag: Italy,
                country: 'Італія',
                phone: '+390697636235',
            },
            // {
            //     flag: Germany,
            //     country: 'Німеччина',
            //     phone: '+41445083049',
            // },
            {
                flag: Spain,
                country: 'Іспанія',
                phone: '+34854785095',
            },
            {
                flag: Portugal,
                country: 'Португалія',
                phone: '+351304500095',
            },
            {
                flag: UK,
                country: 'Велика Британія',
                phone: '+447418365798',
            },
            {
                flag: Switzerland,
                country: 'Швейцарія',
                phone: '+41445083049',
            },
            {
                flag: USA,
                country: 'США',
                phone: '+12012971181',
            },
        ],
    };

    const renderCountries = (item, i) => {
        return (
            <div className="countries_item" key={`segmentation_item-${i}`}>
                <img className='countries_flag' src={item.flag} alt="" />
                <p className='countries_country'>{item.country}</p>
                <p className='countries_phone'>{item.phone}</p>
                <a className='countries_call' href={`tel:${item.phone}`}>
                    Подзвонити
                    <img className='countries_call--img' src={call} alt="" />
                </a>
            </div>
        );
    };

    return (
        <div className="countries_wrap" id='countries'>
            <div className='countries container'>
                <h2 className='countries_title'>Ми покриваємо <br />7 країн</h2>
                <div className="countries_items">
                    {state.countries.map((item, i) => renderCountries(item, i))}
                </div>
            </div>
        </div>
    );
};

export default Countries;