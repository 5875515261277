import React from 'react';
import './Mission.css';

const Mission = (props) => {
    return (
        <div className="mission_wrap" id='mission'>
            <div className="container mission">
                <h2 className="mission_title">Наша місія</h2>
                <div className="mission_desc--block">
                    <p className="mission_desc">Допомагаємо українцям жити більш гідним життям надаючи переклад та поради з культурної адаптації. Українці не біженці! Ми - вимушені гості поки в нашій країні йде капітальний ремонт. </p>  
                    <p className="mission_desc">Для того, щоб до наших не ставилися як для людей "другого сорту" наші волонтери допомагають порозумітись у важливих діалогах та надають впевненність в тому, що українці дбають про своїх.</p>
                </div>

            </div>
        </div>
    );
};

export default Mission;