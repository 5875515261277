import "./OpenMenu.css";
import { Link } from "react-scroll";
import Contact_us from '../../../Contact_us/Contact_us'

const OpenMenu = (props) => {
  return (
    <>
      <div className="openMenu">
        <ul>
          <li className="menu_pc--without_before">
            <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='service'
              spy={true}
              smooth={true}
              offset={-94}
              >
                Як працює сервіс
            </Link>
          </li>
          <li>
            <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='numbers'
              spy={true}
              smooth={true}
              offset={-94}
              >
                Ми в цифрах
            </Link>
          </li>
          <li>
              <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='countries'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Країни покриття
            </Link>
          </li>
          <li>
              <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='join'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Перекладачам
            </Link>
          </li>
          <li>
              <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='support'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Донорам
            </Link>
          </li>
          <li>
              <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='partners'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Наші партнери
            </Link>
          </li>
          <li className='menu_pc--after'>
              <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Команда
            </Link>
          </li>
          <li className='menu_pc--after'>
              <Link
              onClick={props.closeFunc}
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Перелік мов
            </Link>
          </li>
        </ul>
        <Contact_us />
        <a className="menu_inst" href="https://instagram.com" target='_blank' rel='norefferer' >
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" fill="white"/>
            <circle cx="22" cy="22.0002" r="8.86207" stroke="#3D67D5" stroke-width="2"/>
            <circle cx="32.6207" cy="11.3794" r="2.27586" fill="#3D67D5"/>
            <rect x="4.48071" y="4.48047" width="35.1278" height="36.288" rx="7" stroke="#3D67D5" stroke-width="2"/>
          </svg>
           Pereklad_help
           </a>
      </div>
    </>
  );
};
export default OpenMenu;
