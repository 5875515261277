import React, { useState } from "react";
import { Link } from "react-scroll";
import OpenMenu from "./OpenMenu/OpenMenu.jsx";
import logoGeekle from '../../../img/logo.svg'
import './Menu.css';

const Menu = (props) => {
  const [showText, setShowText] = useState(false);
  const onClick = () => setShowText(!showText);

    return (
      <>
        <ul className='menu'>
          <li className='menu_pc menu_pc--without_before'>
              <Link
              activeClass="active"
              to='service'
              spy={true}
              smooth={true}
              offset={-94}
              >
                Про сервіс
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='numbers'
              spy={true}
              smooth={true}
              offset={80}
              >
              Ми в цифрах
            </Link>
          </li>
          <li className='menu_pc menu_pc--after'>
              <Link
              activeClass="active"
              to='countries'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Доступні країни
            </Link>
          </li>
          <li className='menu_pc menu_pc--after'>
              <Link
              activeClass="active"
              to='support'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Донорам
            </Link>
          </li>
          <li className='menu_pc menu_pc--after'>
              <Link
              activeClass="active"
              to='join'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Перекладачам
            </Link>
          </li>
          <div className="mobile-menu">
            <div className={`${showText === true && 'active'} logo-mob`}> 
              <img src={logoGeekle} alt="" /> 
            </div>
            <div className="mobile-menu_img-block">
                <div className={`${showText === true && 'active'} menu_toggle_wrapper`} onClick={onClick}>
                  <span className="menu_toggle"></span>
                </div>
            </div>
          <div>
            {showText ? (
              <React.Fragment>
                <OpenMenu closeFunc={onClick}/>
                <div className="mob_blur_bcg"></div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        </ul>
      </> 
    );
  }
        
export default Menu;