import React from 'react';
import './TranslateTo.css';
import France from '../../img/countries/France.png'
import Italy from '../../img/countries/Italy.png'
import Portugal from '../../img/countries/Portugal.png'
import Spain from '../../img/countries/Spain.png'
import UK from '../../img/countries/UK.png'
import Switzerland from '../../img/countries/Switzerland.png'
import creative from '../../img/TranslateTo/creative.svg'
import USA from '../../img/countries/USA.jpg'

const TranslateTo = (props) => {
    const state = {
        countries: [
            {
                flag: Italy,
                country: 'Італія',
                phone: '+390 697 636 235',
            },
            // {
            //     flag: Germany,
            //     country: 'Німеччина',
            //     phone: '+41445083049',
            // },
            {
                flag: Portugal,
                country: 'Португалія',
                phone: '+351 304 500 095',
            },
            {
                flag: UK,
                country: 'Велика Британія',
                phone: '+447 418 365 798',
            },
            {
                flag: Spain,
                country: 'Іспанія',
                phone: '+348 547 850 95',
            },
            {
                flag: France,
                country: 'Франція',
                phone: '+337 576 900 91',
            },
            {
                flag: Switzerland,
                country: 'Швейцарія',
                phone: '+414 450 830 49',
            },
            {
                flag: USA,
                country: 'США',
                phone: '+120 129 711 81',
            },
        ],
    };

    const renderCountries = (item, i) => {
        return (
            <div className="translateTo_item" key={`segmentation_item-${i}`}>
                <div className='translateTo_item--content'>
                    <img className='translateTo_flag' src={item.flag} alt="" />
                    <div className='translateTo_info'>
                        <p className='translateTo_country'>{item.country}</p>
                        <p className='translateTo_phone'>{item.phone}</p>
                    </div>
                </div>
                <a className='translateTo_call second_btn button' href={`tel:${item.phone}`}>
                    Подзвонити
                </a>
            </div>
        );
    };

    return (
        <div className="translateTo_wrap">
            <div className='translateTo container'>
                <h2 className='translateTo_title'>Якщо ви опинилися за кордоном ми перекладемо вам наступними мовами</h2>
                <div className='translateTo_content'>
                    <img className='translateTo_img' src={creative} alt="" />
                    <div className="translateTo_items">
                        {state.countries.map((item, i) => renderCountries(item, i))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TranslateTo;