import './main.css';
import { Link } from "react-scroll";
import React from 'react';
import creative from '../../img/main/creative.svg'
import creativeMob1 from '../../img/main/creative_mob1.svg'
import Popup_sponsors from '../Popup_sponsors/Popup_sponsors'

export default class Main extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         firstScreenHeight: document.documentElement.clientHeight,
    //     }

    //     this.changeScreenHeight = this.changeScreenHeight.bind(this);
    // }

    // changeScreenHeight() {
    //     this.setState({
    //         firstScreenHeight: document.documentElement.clientHeight
    //     });

    //     document.querySelector('.main_wrap').style.height = this.state.firstScreenHeight + 'px';
    // }

    // componentWillMount = () => {
    //     window.addEventListener("resize", this.changeScreenHeight);
    //     return () => window.removeEventListener("resize", this.changeScreenHeight);
    // }

    // componentDidMount() {
    //     this.changeScreenHeight();
    // }

    render() {
        return (
            <div className='main_wrap'>
                <div className='container main_block'>
                    <div className='main_content main_item'>
                        <h1 className='main_title'>Сервіс волонтерського перекладу для українців за кордоном</h1>
                        <p className='main_undertitle'>Допоможемо тобі порозумітися з іноземцями переклавши твої думки на їх мову, а також надамо поради з культурної адаптації.</p>
                        <div className='main_btns_wrap'>
                            <Popup_sponsors />
                            <Link
                                activeClass="active"
                                to='join'
                                spy={true}
                                smooth={true}
                                offset={-94}
                                className='second_btn button main_btn'
                            >
                                Стати перекладачем
                            </Link>
                        </div>
                    </div>
                    <div className='main_img_block main_item'>
                        <img className='main_img main_img--pc' src={creative} alt="" />
                        <img className='main_img main_img--mob' src={creativeMob1} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}