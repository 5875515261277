import React from 'react';
import './Join.css';
import Popup from './Popup_translators/Popup_translators'

const Join = (props) => { 
    return (
        <div className="join_wrap" id='join'>
            <div className='container join'>
                <h2 className="join_title">Долучитись як перекладач по телефону</h2>
                <div className="join_content">
                    <p className='join_text'>Вам допомогуть підʼєднатись до сервісу, вибрати робочі години та навчать слідкувати стандартам сервісу</p>
                    <Popup />
                </div>
            </div>
        </div>
    );
};

export default Join;