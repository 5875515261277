import React, {useState} from 'react';
import './Free.css';

const Free = (props) => {
    return (
        <div className="free_wrap">
            <div className='container free'>
                <h2 className='free_title'>Це безкоштовно?</h2>
                <p className='free_text'>Так! Сервіс створено волонтерами з метою допомогти нашім під час війни розумітись з європейцями та не відчувати себе другосортними людьми під час вимушеної еміграції у зʼвязку з війною в Україні.</p>
            </div>
        </div>
    );
};

export default Free;