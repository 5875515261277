import React from 'react';
import './Header.css';
import Menu from './Menu/Menu.jsx';
import Logo from '../../img/logo.svg';

const Header = (props) => {
    const [scroll, setScroll] = React.useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={scroll > 30 ? "header_wrap header_wrap--scroll" : "header_wrap"}>
            <header className="header_container">
                <div className='header_logo'>
                    <a href='#'>
                        <img src={Logo} alt="Logotype" />
                    </a>
                </div>
                <Menu
                    speakers={props.speakers}
                    workshops={props.workshops}
                    sponsors={props.sponsors}
                    pc={props.pc}
                    team={props.team}
                    packages={props.packages}
                />
            </header>
        </div>
    );
}
  
export default Header;