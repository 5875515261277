import React, { useState } from 'react';
import './How_it_works.css';
import img1 from '../../img/how_it_works/img1.svg'
import img2 from '../../img/how_it_works/img2.svg'
import img3 from '../../img/how_it_works/img3.svg'
import audio_bcg1 from '../../img/audios/audio_bcg1.png'
import audio_bcg2 from '../../img/audios/audio_bcg2.png'
import audio_micro from '../../img/audios/audio_micro.svg'
import audio_play from '../../img/audios/audio_play.svg'
import audio_pause from '../../img/audios/audio_pause.svg'
import audio_waves from '../../img/audios/audio_waves.svg'
import doctorAudio from '../../audios/doctor.mp3'
import govermentAudio from '../../audios/goverment.mp3'
import Popup_sponsors from '../Popup_sponsors/Popup_sponsors'

const How_it_works = (props) => {
    const [audio1Play, setAudio1Play] = new useState(false);
    const [audio2Play, setAudio2Play] = new useState(false);

    const state = {
        topics: [
            {
                img: img1,
                topic: '1/ Ви набираєте наш номер',
            },
            {
                img: img2,
                topic: '2/ Ставите на гучномовець',
            },
            {
                img: img3,
                topic: '3/ Наші волонтери перекладають для вас',
            }, 
        ],
    };

    const renderTopics = (item, i) => {
        return (
            <div className="how_it_works_item" key={`how_it_works_content-${i}`}>
                <img className='how_it_works_img' src={item.img} alt="" />
                <p className='how_it_works_topic'>{item.topic}</p>
            </div>
        );
    };

    const playAudio1 = () => {
        setAudio1Play(true);
        document.querySelector('.audio1').play();
    };

    const pauseAudio1 = () => {
        setAudio1Play(false);
        document.querySelector('.audio1').pause();
    };

    const playAudio2 = () => {
        setAudio2Play(true);
        document.querySelector('.audio2').play();
    };

    const pauseAudio2 = () => {
        setAudio2Play(false);
        document.querySelector('.audio2').pause();
    };

    return (
        <div className="how_it_works_wrap" id='service'>
            <div className='container how_it_works'>
                <h2 className='how_it_works_title'>Як працює сервіс?</h2>
                <div className="how_it_works_content">
                    <div className="how_it_works_block">
                        {state.topics.map((item, i) => renderTopics(item, i))}
                    </div>
                    <p className="how_it_works_desc">Якщо ви потребуєте допомогу в порозумінні з фахівцями у вирішенні важливих питань, або потребуєте поради з культурної адаптації -ми допоможемо вам!</p>
                    <div className='audio_block'>
                        <h4 className='audio_title'>Послухайте приклади</h4>
                        <div className='audio_block--content'>
                            <div className='audio_items'>
                                <div className="audio_item" style={{backgroundImage: `url(${audio_bcg1})`}}>
                                    <img className='audio_micro' src={audio_micro} alt="" />
                                    <div className="audio_item--content">
                                        {!audio1Play && <img onClick={playAudio1} className='audio_play' src={audio_play} alt="" />}
                                        {audio1Play && <img onClick={pauseAudio1} className='audio_play' src={audio_pause} alt="" />}
                                        <audio className='audio1' src={doctorAudio}></audio>
                                        <p className='audio_listen'>Прослухати</p>
                                        <img className='audio_waves' src={audio_waves} alt="" />
                                    </div>
                                    <p className='audio_desc'>Розмова з доктором</p>
                                </div>
                                <div className="audio_item" style={{backgroundImage: `url(${audio_bcg2})`}}>
                                    <img className='audio_micro' src={audio_micro} alt="" />
                                    <div className="audio_item--content">
                                        {!audio2Play && <img onClick={playAudio2} className='audio_play' src={audio_play} alt="" />}
                                        {audio2Play && <img onClick={pauseAudio2} className='audio_play' src={audio_pause} alt="" />}
                                        <audio className='audio2' src={govermentAudio}></audio>
                                        <p className='audio_listen'>Прослухати</p>
                                        <img className='audio_waves' src={audio_waves} alt="" />
                                    </div>
                                    <p className='audio_desc'>Державні установи</p>
                                </div>
                            </div>
                            <Popup_sponsors />
                            {/* <a className='button audio_btn' href="">Підтримати</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default How_it_works;