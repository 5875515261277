import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './team.css';
import Ed from '../../img/Team/Ed.png';
import Roman from '../../img/Team/Roman.png';
import Alina from '../../img/Team/Alina.png';

export default class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: [
        {
          photo: Ed,
          name: 'Ед Недін',
          position: 'Засновник сервісу, фінансує його та здійснює загальне керування',
        },
        {
          photo: Alina,
          name: 'Аліна Маслова',
          position: 'Менеджер з розвитку сервісу, набирає волонтерів та створює перекладацьке комьюніті',
        },
        {
          photo: Roman,
          name: 'Роман Кайло',
          position: 'Керуючий колл центру, відповідає за технічну частину, розклад роботи волонтерів та стандарти роботи',
        },
      ]
    }
  }

  renderTeam(item, i) {
    return (
      <div className="team_item" key={`team-${i}`}>
          <img className="team_photo" src={item.photo} alt={item.name} />
          <p className="team_name">{item.name}</p>
          <p className="team_position">{item.position}</p>
      </div>
    )
  }

  render() {
      return (
          <div className="team_wrap" id='team'>
                  <div className='container team'>
                    <h2 className="team_title">Наша команда</h2>
                      <div className="team_block team_block--pc">
                        {this.state.team.map((item, i) => this.renderTeam(item, i))}
                      </div>
                      <div className="team_block team_block--mob">
                      <Carousel showStatus={false} infiniteLoop autoPlay centerMode={true}>
                        {this.state.team.map((item, i) => this.renderTeam(item, i))}
                      </Carousel>
                    </div>
                  </div>
          </div>
      )
  }
}