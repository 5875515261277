import React from 'react';
import close from '../../img/close.svg'
import Form from './Form/Form'

export default class Popup_sponsors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vis: false,
        }

        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
    }

    openPopup() {
        this.setState({ vis: true });
    }

    closePopup() {
        this.setState({ vis: false });
    }

    togglePopup() {
        this.setState({ vis: !this.state.vis });
    }


    render() {
        return (
            <>
                <button className='button' onClick={this.togglePopup}>Підтримати</button>
                {this.state.vis && <div className='popup'>
                    <div className='popup_container'>
                        <h2 className='popup_title'>Я хочу стати спонсором</h2>
                        <div className='popup_form'>
                            <Form />
                        </div>
                    </div>
                    <img className='popup_close' src={close} onClick={this.togglePopup} alt="" />
                </div>}
            </>
        )
    }
}