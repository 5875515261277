import React from 'react';
import './Partners.css';
import telecom from "../../img/Partners/telecom.svg";
import actions from "../../img/Partners/actions.png";
import fedoriv from "../../img/Partners/fedoriv.png";
import susidy from "../../img/Partners/susidy.png";
import geekle from "../../img/Partners/geekle.svg";
import Popup_partners from '../Popup_partners/Popup_partners'

const Partners = (props) => {
    const state = {
        Partners_item: [
            {
                img: geekle,
            },
            {
                img: telecom,
            },
            {
                img: actions,
            },
            {
                img: fedoriv,
            },
            {
                img: susidy,
            },
        ],
    };

    const renderPartners_item = (item, i) => {
        return (
            <div className='partners_item' key={`partners_item-${i}`}>
                <img className='partners_img' src={item.img} alt="" />
            </div>

        );
    };

    return (
        <div className="partners_wrap" id='partners'>
            <div className="container partners">
                <h2>Наші партнери</h2>
                <div className='partners_items'>
                    {state.Partners_item.map((item, i) => renderPartners_item(item, i))}
                </div>
                <Popup_partners />
            </div>
        </div>
    );
};

export default Partners;